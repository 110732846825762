@use '~styles/variables' as *;

.item {
  position: relative;
  display: flex;
  flex-direction: column;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $color-mischka;
  }

  &:last-child {
    justify-content: flex-end;
  }
}

.card {
  height: calc(100% - 24px);
}
