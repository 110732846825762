@import '../../styles/variables/colors';

.container {
  width: 260px;
  border: 1px solid $black;
  padding: 20px 15px;
  border-radius: 8px;
}

.title {
  font-size: 20px;
}

.titleBig {
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.text,
.textBig {
  margin: 0;
}

.textBig {
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  word-break: break-word;
}
