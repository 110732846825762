.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.isDefault {
  width: 70px;
}

.action {
  width: 89px;
}

.address {
  &EditMode {
    padding-left: 24px !important;
  }
}

.phone {
  width: 170px;
}

.withoutStyle {
  background: none;
  border: none;
}

.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;
  justify-content: center;

  label {
    margin-bottom: 0;
  }

  div {
    margin-left: 10px;
  }
}
