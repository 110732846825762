@import '../../styles/variables/colors';

.button {
  border: none;
  background: none;
  color: $blue-dark;
}

.actions {
  display: flex;
  align-items: center;
}
