@import '../../styles/variables/colors';

.wrapper {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  align-items: flex-start;
}

.card {
  margin-right: 20px;
  margin-bottom: 20px;
}

.link {
  display: block;
  font-family: 'RiformaLL', sans-serif;
}

.errorText {
  color: rgba($red, 0.6);
}

.address {
  display: flex;
  flex-direction: column;
}
