@import '../../styles/variables/colors.scss';

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  font-weight: 500;
  color: $black;
  margin: 0;
}

.subtitle {
  font-size: 12px;
  opacity: 0.85;
}
