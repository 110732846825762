@import '../../../styles/variables/colors.scss';

.title {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  margin-bottom: 14px;
  color: $black;
}

.subtitle {
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  margin-bottom: 25px;
  color: $black;
}

.buttons {
  margin-top: 30px;
}
