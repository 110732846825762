@import '../../styles/variables/colors';

.container {
  position: relative;
  width: 260px;
  height: 140px;
  border: thin solid $black;
  border-radius: 8px;
  padding: 10px 14px;
  transition: 0.3s;
  background-color: transparent;
  overflow: hidden;
}

.activeContainer {
  background-color: $black;

  * {
    color: $white;
  }

  svg {
    path {
      fill: $white;
    }
  }
}

.selectedContainer {
  background-color: rgba($secondary, 0.7);
  border: thin solid rgba($secondary, 0.7);

  * {
    color: $white;
  }

  svg {
    path {
      fill: $white;
    }
  }
}

.title {
  text-transform: uppercase;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 6px;
}

.numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 1.3px;
}

.numberDot {
  font-size: 18px;
  margin-right: 13px;
  margin-bottom: 0;
  line-height: 2;
}

.digits {
  margin: 0;
  font-weight: 600;
}

.name {
  text-transform: uppercase;
  font-weight: 600;
  margin: 3px 0 0;
}

.date {
  margin: 3px 0 0 0;
  font-weight: 600;
  letter-spacing: 1.3px;
}

.brand {
  position: absolute;
  bottom: 8px;
  right: 16px;
}
