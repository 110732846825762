@import '../../../styles/variables/colors.scss';

.container {
  border-bottom: 1px solid $mischka;

  &:last-child {
    border-bottom: none;
  }

  :global(.table-bordered thead th) {
    border: 1px solid $mischka;
  }

  :global(.table-bordered tbody td) {
    border: 1px solid $mischka;
  }
}

.text {
  margin-bottom: 0;
  font-weight: 700;
  word-break: break-word;

  span {
    font-weight: 400;
  }
}
