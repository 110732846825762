@use '~styles/variables' as *;

.root {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}

.items {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 16px 0;
}

.tierTitle {
  font-family: 'RiformaLL', sans-serif;
  // font-size: 48px;
  color: $color-black;
  text-transform: uppercase;
  text-align: center;
}

.tierSubtitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.capacity {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-black;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.description {
  padding: 30px 40px;
  margin-top: 16px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 30px 0;
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    padding: 8px 12px;
  }

  & ul {
    list-style-position: inside;
    padding-inline-start: 0;
  }
}

.total {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 0 16px;

  &Btn {
    border-radius: 20px !important;

    @media (max-width: $media-xl) {
      margin-bottom: 40px;
    }
  }
}
