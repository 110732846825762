@use '~styles/variables' as *;

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.bottles {
  display: flex;
}

.bottle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: $color-gray-300;
  border: 1px solid rgba(0, 0, 0, 1);
  color: $color-black;
}

.title {
  color: $color-black;
}

.description {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  margin-left: 10px;
}

.right {
  width: 60%;
}

.card {
  position: relative;
  width: 49%;
  padding: 20px;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
  border-radius: 15px;
  background: $color-gray-300;
  color: $color-black;
  opacity: 0.85;
  border: 1px solid rgba(0, 0, 0, 0.5);

  @media (min-width: 1200px) and (max-width: 1600px) {
    .description {
      font-size: 10px;
    }

    .right {
      font-size: 12px;
    }

    .bottles {
      flex-wrap: wrap;
    }
  }

  &.active {
    opacity: 1;
    background: $color-black;
    border: 1px solid rgba(0, 0, 0, 1);
    color: $color-white;

    .bottle {
      background-color: $color-black;
      border: 1px solid $color-white;
      color: $color-white;
    }

    .title {
      color: $color-white;
    }
  }
}

.left {
  width: 40%;
}

.period {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
}
