@import '../../styles/variables/media.scss';

.wrapper {
  width: calc(100% + 20px);
  display: flex;
  flex-wrap: wrap;
}

.card {
  margin-right: 20px;
  margin-bottom: 20px;
}
