@import '../../styles/variables/colors';
@import '../../styles/variables/media';

.disabledStep {
  opacity: 0.3;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.buttons.firstTab {
  display: flex;
  justify-content: flex-end;
}

.buttons.thirdTab {
  @include minPhoneMedia {
    display: block;
  }
}

.label {
  display: flex;
  align-items: center;
  margin: 0;
}

.input {
  &:global(.form-check-input) {
    margin: 0 5px 0 0;
    position: relative;
  }
}
