.text {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.textBlock {
  margin-right: 66px;

  &:last-child {
    margin-right: 0;
  }
}

.button {
  width: 120px;

  &:global(.btn-success) {
    background-color: #57b78f;
    border-color: #57b78f;
  }

  &:global(.btn-danger) {
    background: #cb5858;
    border-color: #cb5858;
  }
}

.label {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.tier {
  display: flex;
  align-items: flex-end;
}
