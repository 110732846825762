@import '../../../styles/variables/colors.scss';

.block {
  text-align: center;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.items {
  padding: 15px 0 0;
  border-top: 1px solid #000;
  list-style: none;
}

.item {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.button {
  width: 200px;
  margin: 0 auto 20px;
}
