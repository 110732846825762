@import '../../../styles/variables/colors.scss';

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: $black;
  margin: 0;
}
