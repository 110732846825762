@import '../../styles/variables/colors.scss';

.title {
  padding-top: 20px;
  border-top: 1px solid $mischka;
  margin-bottom: 20px;
}

.refundTable {
  margin-bottom: 20px;
  padding-bottom: 10px;

  &:last-child {
    border-bottom: none;
  }
}
