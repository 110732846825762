@import '../../styles/variables/colors';

.container {
  width: 200px;
  padding: 18px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $black;
  border-radius: 12px;
}

.image {
  width: 100%;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
}

.title {
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  margin-top: 4px;
  font-weight: 600;
}

.blockPrice {
  margin-top: 8px;
  text-align: center;
}

.price {
  font-weight: 600;
  font-size: 16px;
  margin-left: 5px;
}

.action {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.button {
  &:not(:last-child) {
    margin-right: 10px;
  }
}
