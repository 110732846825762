@import '../../../styles/variables/colors';

.root {
  background-color: $white;
}

.background {
  background-image: url('../../../assets/images/auth-background.jpg');
  height: 100vh;
  background-size: cover;
  background-position: center;

  @media (max-width: 991px) {
    display: none;
  }
}

.title {
  margin-top: 24px;
  font-size: 24px;
}

.logo {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.header {
  height: 20vh;
}

.content {
  height: 80vh;
}

.block {
  display: flex;
  justify-content: center;

  &:global(.row) {
    margin: 0;
  }
}
