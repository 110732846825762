@import '../../styles/variables/colors';

.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: rgba(3, 6, 16, 0.4);
  animation: fadeIn 0.3s;
}

.popupBase {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: calc(100vw - 20px);
  height: 100%;
  max-height: 800px;
  padding: 20px 30px;
  border-radius: 10px;
  transition: 0.2s;
  animation: zoomIn 0.3s;
  box-shadow: 0 8px 26px 0 rgba($dark, 0.4);
  background: $white;
}

.closeButton {
  background: none;
  border: none;
  font-size: 32px;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 16px;
  top: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.5, 0.5);
  }

  100% {
    transform: scale(1, 1);
  }
}
