//
// _buttons.scss
//

button,
a {
  outline: none !important;
}

.btn-light {
  border: 1px solid darken($light, 2%);
}

// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
  padding: 10px 37px;
}

.btn-dark,
.btn-secondary {
  color: $white;
  background-color: $black;
}

.btn-rounded-outline {
  border-radius: 30px;
  padding: 10px 37px;
  background: transparent;
  color: $black;
}

.btn-outline-light {
  color: $gray-900;
}

.btn-white {
  background-color: $white;
}
