@import '../../styles/variables/colors';

.bottle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: $gray-300;
  border: 1px solid rgba(0, 0, 0, 1);
  color: $black;
}

.title {
  color: $black;
}

.container {
  width: 100%;
  position: relative;
  padding: 20px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  cursor: pointer;
  border-radius: 15px;
  background: $gray-300;
  color: $black;
  opacity: 0.85;
  border: 1px solid rgba($black, 0.5);

  @media (min-width: 1200px) and (max-width: 1600px) {
    .description {
      font-size: 10px;
    }

    .right {
      font-size: 12px;
    }

    .bottles {
      flex-wrap: wrap;
    }
  }
}

.active {
  opacity: 1;
  background: $black;
  border: 1px solid rgba(0, 0, 0, 1);
  color: $white;

  .bottle {
    background-color: $black;
    border: 1px solid $white;
    color: $white;
  }

  .title {
    color: $white;
  }
}

.period {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.bottles {
  display: flex;
}

.description {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  align-items: flex-start;
  text-align: left;

  & ul {
    list-style-position: inside;
    padding-inline-start: 0;
  }
}
