//
// _table.scss
//

.table {
  th {
    font-weight: $font-weight-bold;
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  th,
  td {
    @media screen and (max-width: 768px) {
      padding: 0.475rem 0.5rem;
    }
  }
}

//Table centered
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

.table-grey {
  th {
    border-top: none !important;
    border-color: black !important;
  }

  td {
    border-bottom: thin solid black !important;
  }
}
