@use '~styles/variables' as *;

.root {
  background-color: $color-black;
  color: $color-white;
  font-family: 'RiformaLL', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;

  @media (max-width: 420px) {
    .title {
      font-size: 70px;
    }

    .subtitle {
      font-size: 40px;
    }
  }
}

.title {
  font-size: 123px;
  display: flex;
  justify-content: center;
}

.subtitle {
  font-size: 104px;
}

.backHomeLink {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: $color-white;
  text-transform: uppercase;

  &:hover {
    color: $color-white;
    opacity: 0.8;
  }
}
