.block {
  padding-top: 16px;
  margin-top: 16px;
  border-top: thin solid black;
}

.blockNoBorder {
  border-top: none;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 600;
}

.small {
  font-size: 13px;
}

.itemTitle {
  margin: 0;
  min-width: 50%;
}

.itemInfo {
  display: flex;
  align-items: center;
}

.itemCount {
  margin: 0;
  width: 30px;
}

.itemDiscount {
  text-decoration: line-through;
}

.itemPrice {
  margin: 0;
  width: 70px;
  text-align: right;
}
