@import '../../../styles/variables/colors';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  margin: 24px 0;
}

.popup {
  width: 350px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  width: 60px !important;
  height: 60px !important;
}

.text {
  text-align: center;
  font-size: 20px;
  margin-bottom: 16px;
}
