.link {
  &:global(.btn) {
    font-size: 18px;
    padding: 0;

    &:hover {
      text-decoration: none;
    }
  }
}
