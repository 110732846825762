.caption {
  font-size: 12px;
}

.orderInfo {
  margin-top: 16px;
  padding-top: 16px;
}

.backToOrders {
  text-align: right;
}

.blockTitle {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.blockContent {
  font-size: 13px;
  margin: 0;
}
