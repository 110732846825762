@import '../../styles/variables/colors';

.divider {
  width: 100%;
  height: 1px;
  border-top: 1px solid $black;
  margin: 14px 0;
}

.remaining {
  text-align: center;
  margin: 0;
}

.priceWrapper {
  padding: 0 16px;
}

.price {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.priceBlock {
  display: flex;
  align-items: center;
}

.priceLine {
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px 0 0;
}

.priceText {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.tax {
  margin-left: 15px;
}

.priceButton {
  width: 100%;
  margin-top: 20px;
  border-radius: 20px !important;
}

.smallFees {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.coupon {
  height: 40px !important;
  padding-right: 65px !important;

  &Button {
    margin-left: -65px;
    border-radius: 7px !important;
    width: 65px;
  }

  &Form {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &Information {
    display: flex;
    flex-direction: row;

    &Message {
      font-size: 16px;
      margin-bottom: 10px;
    }

    &RemoveButton {
      margin-top: -1px;
      margin-left: 5px;
      font-size: 18px;
      cursor: pointer;
    }
  }

  &Container {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  &Error {
    margin-top: 4px;
    color: red;
  }
}
