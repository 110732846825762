@import '../../styles/variables/colors.scss';

.container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.field {
  width: 100%;
  position: relative;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: 20px;
}

.fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveButton {
  margin-top: 3px;
  margin-left: 12px;

  &Container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
}

.customError {
  margin-left: 10px;
}

.suggestion {
  position: absolute;
  background-color: $light;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  z-index: 5;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.suggestionItem {
  transition: opacity 0.2s;
}

.suggestionItem:hover {
  opacity: 0.8;
  cursor: pointer;
}

.inputError {
  border: 1px $red solid !important;
}

.error {
  color: $red;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.toggleButton {
  position: absolute;
  top: 45px;
  left: -15px;
  border: 7px solid transparent !important;
  border-left: 10px solid $dark-gray !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  transform-origin: 5px center !important;
  transform: rotate(0deg);
  transition: transform 0.3s linear !important;

  &WithoutLabel {
    top: 17px;
  }

  &Opened {
    transform: rotate(90deg);
  }
}

.toggleButton,
.toggleButton:active,
.toggleButton:focus {
  outline: none !important;
  box-shadow: none !important;
}
