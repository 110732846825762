$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

@function get-vw($target) {
  $vwContext: (375 * 0.01) * 1px;

  @return ($target / $vwContext) * 1vw;
}

@function get-rem($target) {
  $targetPx: (14) * 1px;

  @return ($target / $targetPx) * 1rem;
}

@mixin maxPhoneMedia {
  @media screen and (max-width: $md) {
    @content;
  }
}

@mixin minPhoneMedia {
  @media screen and (max-width: $sm) {
    @content;
  }
}
