//
// Forms.scss
//

label[required] {
  &::after {
    content: '*';
  }
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: 1.25rem;
  .form-check-input {
    right: 0;
    margin-left: 0;
  }

  .form-check-label {
    display: block;
  }
}

.custom-control-right {
  padding-left: 0;
  padding-right: 1.5rem;
  display: inline-block;
  .custom-control-label {
    display: inline-block;
    &:before,
    &:after {
      left: auto;
      right: -1.5rem;
    }
  }

  .custom-control-input {
    left: auto;
  }
}

.custom-control-label {
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: $black;
  border-color: $black;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: $black;
  border-color: $black;
  opacity: 0.5;
}

.form-control {
  height: 45px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 7px;
  background-color: $body-bg;
  font-family: 'RiformaLL', sans-serif;
}

input:focus,
select:focus {
  background-color: $body-bg !important;
}
