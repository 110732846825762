@import '../../styles/variables/colors';

.container {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background: none;
  border: 1px solid $black;
  border-radius: 50%;
  transition: 0.2s;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    border: 1px solid rgba($black, 0.4);
  }

  &:hover:enabled {
    background: $black;
    color: $white;
  }
}

.darkContainer {
  background: $black;
  color: $white;
}

.notClickableContainer {
  cursor: default !important;

  &:hover:enabled {
    background: inherit;
    color: inherit;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-size: 12px;
  line-height: 12px;
}
