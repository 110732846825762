.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.withoutStyle {
  background: none;
  border: none;
}

.checkbox {
  display: flex !important;
  align-items: center;
  padding-left: 0 !important;
  justify-content: flex-start;

  label {
    margin-bottom: 0;
  }
}

.actions {
  margin-top: 5px;
}
