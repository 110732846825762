@import '../../styles/variables/media.scss';

.block {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}

.title {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0;
  color: rgb(139, 139, 139);
}

.value {
  margin-bottom: 0;
  font-size: get-rem(16px);
}
