@import '../../styles/variables/colors';

.container {
  margin-top: -50px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 8px;
}

.subtitle {
  margin-bottom: 48px;
  color: $secondary;
}

.field {
  margin-bottom: 18px;
}

.button {
  margin: 10px 0 24px;
}
