@use '~styles/variables' as *;

.container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.field {
  position: relative;
  width: 100%;
}

.book {
  width: 100%;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-right: 12px;
  padding-left: 12px;
}

.buttons {
  margin: 15px 0;
}

.toggleButton {
  background: none;
  border: none;
  appearance: none;
  color: $color-han-blue;
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}
