@import '../../styles/variables/colors.scss';

.item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.button {
  outline: none;
  border: none;
  background: none;
  font-size: 22px;
  line-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  margin-left: 14px;
  margin-bottom: 0;
  color: #55616b;
}
