.container {
  display: flex;
  align-items: center;
}

.button {
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.icon {
  font-size: 20px;
}
