@import '../../styles/variables/colors';

.container {
  position: relative;
}

.text {
  font-size: 16px;
  line-height: 22px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.boldText {
  font-weight: 600;
  color: $black;
}
