.container {
  max-width: 100%;
  width: 600px;
  padding: 15px 0 30px;
}

.button {
  width: 100px;
}

.label {
  display: flex;
  align-items: center;
  margin: 0;
}

.input {
  margin-right: 5px;

  &:global(.form-check-input) {
    margin: 0 5px 0 0;
    position: relative;
  }
}
