@import '../../styles/variables/colors.scss';

.subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 40px 0 30px;
}

.block {
  text-align: center;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.button {
  width: 200px;
  margin: 0 auto 20px;
}

.back {
  display: block;
  margin-left: auto;
  margin-bottom: 10px;

  &:global(.btn-block) {
    width: auto;
  }
}
