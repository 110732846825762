.productWrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
}

.productsTitle {
  width: calc(100% - 24px);
  padding: 0 20px;
  margin-right: 24px;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    margin-top: -35px;
  }
}

.productCard {
  margin-right: 24px;
  margin-bottom: 24px;
}

.button {
  width: 100%;
  margin-top: 10px;
  border-radius: 20px !important;
}
