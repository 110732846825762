.shipments {
  margin-left: 50px;
}

.link {
  &:global(.nav-link) {
    padding: 0;
    display: inline;
  }
}
