@import '../../../styles/variables/colors.scss';

.link {
  font-size: 22px;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: $blue-dark;
  position: relative;

  &:hover {
    border-bottom: 1px solid $blue-dark;
  }
}

.info {
  width: 100%;
  height: 100%;
}
