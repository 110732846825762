.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.field {
  margin-bottom: 18px;
}

.button {
  margin: 10px 0 24px;
}
