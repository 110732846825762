@use '~styles/variables' as *;

.container {
  transition: 0.3s;
}

.containerLoading {
  opacity: 0.6;
}

.bottles {
  display: flex;
}

.bottle {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: $color-gray-300;
  border: 1px solid rgba(0, 0, 0, 1);
  color: $color-black;
}

.title {
  color: $color-black;
}

.description {
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  & ul {
    list-style-position: inside;
    padding-inline-start: 0;
  }
}

.card {
  position: relative;
  padding: 20px;
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  cursor: pointer;
  border-radius: 15px;
  background: $color-gray-300;
  color: $color-black;
  opacity: 0.85;
  border: 1px solid rgba(0, 0, 0, 0.5);

  @media (min-width: 1200px) and (max-width: 1600px) {
    .description {
      font-size: 10px;
    }

    .right {
      font-size: 12px;
    }

    .bottles {
      flex-wrap: wrap;
    }
  }

  &.active {
    opacity: 1;
    background: $color-black;
    border: 1px solid rgba(0, 0, 0, 1);
    color: $color-white;

    .bottle {
      background-color: $color-black;
      border: 1px solid $color-white;
      color: $color-white;
    }

    .title {
      color: $color-white;
    }
  }
}

.period {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
}

.button {
  width: 150px;
  border-radius: 22px !important;
}
