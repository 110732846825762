//
// _pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
  .page-link {
    border-radius: 30px !important;
    margin: 0 3px;
    border: none;
    text-align: center;
  }
}

.page-link {
  background: none;
  border: 1px #000 solid;
  color: #000;

  &:hover {
    color: #fff;
    background-color: #636678;
    border-color: #5d6071;
  }

  &:focus {
    box-shadow: none;
  }
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background: #000;
  border: 1px #000 solid;
}

.page-item.disabled .page-link {
  background: none;
  border: 1px #000 solid;
  color: #000;
}
